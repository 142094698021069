<template>
 <div>
    <div class="withdrawalTop">零钱提现</div>
    <div class="setUpWithdrawal">
      <van-steps center direction="vertical"
      active-color="#FF6400 "
      active-icon="clock"
      inactive-color="#999999" 
      :active="1">
      <van-step>发起提现申请
      </van-step>
      <van-step>
        <div style="color: #333333;">银行处理中</div>
        <div style="color: #999999;">预计{{successObject.predictArriveTime || ''}}前到账</div>
      </van-step>
      <van-step>
        到账成功
      </van-step>
    </van-steps>
    </div>
    <van-divider />
    <div class="Textbottom">
      <div>提现金额</div>
      <div>¥{{ successObject.amount || '' }}</div>
    </div>
    <div class="Textbottom">
      <div>到账银行卡</div>
      <div>{{ successObject.bankCard || '' }}</div>
    </div>
    <div class="Textbottom">
      <div>服务费</div>
      <div>¥{{ successObject.fee || '' }}</div>
    </div>
    <van-button type="default" @click="goWithdrawalBank" color="#F2F2F2">完成</van-button>
 </div>
</template>

<script>
export default {
  data() {
    return {
      successObject:{
        fee:'',
        amount:'',
        bankCard:'',
        predictArriveTime:''
      }
    };
  },
  created() {
    if(this.$route.query.successObject){
      this.successObject =  this.$route.query.successObject || ''
    }
  },
  mounted() {

  },
  methods: {
    goWithdrawalBank(){
      this.$router.push({name:'myWalletBeans'})
    }
  },
};
</script>

<style scoped lang="scss">
.withdrawalTop{
  margin-top: 30px;
  font-size: 18px;
  font-weight: 600;
}
.setUpWithdrawal{
  margin-top: 30px;
}
.setUpWithdrawal{
  padding-left: 40px;
  .van-steps{
  text-align: left;
  .van-step--vertical{
    padding: 20px 20px 20px 0;
  }
}
}
.Textbottom{
  display: flex;
  padding: 6px 20px;
  font-size: 14px;
  color: #888888;
  justify-content: space-between;
  align-content: center;
}
.van-step--vertical::v-deep{
  .van-icon-clock{
    font-size: 24px;
    color: #0799f1 !important;
  }
}
.van-button{
  margin-top: 100px;
  width: 174px;
  height: 40px;
  .van-button__text{
    color: #333333;
  }
}

</style>
